import React from 'react';
import {Link} from 'react-router-dom';
import classes from './DatesTable.module.css';

const DatesTable = (props) => {
    return (
        <React.Fragment>
            <div className={[classes.CardBody,"card-body"].join(" ")}>
                <h1 className={classes.Title}>重要日期</h1>
            </div>
            <br/>
            <p className={classes.Text}>主頁面只列出10個項目，
                <Link to="/important-dates">按此查看更多</Link>
            </p>
            <br/>
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr>
                        <th className={[classes.TR, ""].join("")} scope="col"></th>
                        <th className={[classes.TH, ""].join("")} scope="col">學校名稱</th>
                        <th className={[classes.TH, ""].join("")} scope="col">活動內容</th>
                        <th className={[classes.TH, ""].join("")} scope="col">日期</th>
                        <th className={[classes.TH, ""].join("")} scope="col">備註</th>
                        <th className={[classes.TH, ""].join("")} scope="col">連結</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <th scope="row">1</th>
                        <td>拔萃女書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.dgs.edu.hk/admissions/s1-application-chi" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>德望學校</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www2.ghs.edu.hk/application/login" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>協恩中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.hys.edu.hk/form-one-admission/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>聖保羅男女中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.spcc.edu.hk/chi/admissions/local-admissions/secondary/f1" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>拔萃男書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.dbs.edu.hk/index.php?s=admission&m=g7app" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>香港華仁書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSd4v-jYC6NKiKhMBLgS-ABiy9eligMCqiBRikjvHHmsh7QBmg/closedform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>喇沙書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://us06web.zoom.us/webinar/register/WN_U17I-HQTQ2mU0_Kd0WGYOA" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">8</th>
                        <td>聖保羅書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.spc.edu.hk/content.php?id=156&mid=70-103" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">9</th>
                        <td>華仁書院（九龍）</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://wyk.edu.hk/academic/admission/s1-admissions-0/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>聖保祿學校</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.spcs.edu.hk/admission/s1admission.htm" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    
                </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default DatesTable;